<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Atualizar Role</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>

                <Form
                    ref="form"
                    submitText="Gravar"
                    @submited="updateRole"
                    :loading="loading"
                >
                    <v-row>
                        <v-col cols="12">
                            <Input
                                v-model="fields.display_name"
                                type="name"
                                label="Nome"
                                dense outlined
                                rules="required"
                                vid="name"
                            />
                        </v-col>

                        <v-col cols="12">
                            <MultipeSelect
                                v-model="fields.permissions"
                                :items="permissions"
                                item-text="display_name"
                                item-value="id"
                                dense outlined
                                label="Permissions"
                                rules="required"
                                vid="permissions"
                            />
                        </v-col>
                    </v-row>
                </Form>

                <SuccessSnackbar message="Dados gravados!" v-model="success"/>

                <ErrorSnackbar v-model="error" :message="error_message"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import MenuTabs from "@/components/UI/Tabs/MenuTabs";

    import Form from "@/components/UI/Forms/Form";

    import Input from "@/components/UI/Inputs/Input";

    import MultipeSelect from "@/components/UI/Inputs/MultipeSelect";

    import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

    import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";

export default {
    components: {
      MenuTabs,
      Form,
      Input,
      SuccessSnackbar,
      ErrorSnackbar,
      MultipeSelect,
    },
    data: () => ({
        fields: {
            display_name: null,
            permissions: []
        },
        loading: false,
        success: false,
        error: null,
        permissions: [],
        error_message: "",
    }),
    mounted: function() {
        
        if(!this.$store.getters.hasPermission(["super", "roles.update"])) {
            this.$router.push('/forbidden');
        }

        this.$store.dispatch("listPermissions")
            .then(res => {
                this.permissions = res.data;
            })

        this.$store.dispatch("getRole", this.$route.params.id)
            .then((res) => {
                this.fields.display_name = res.data.display_name;

                this.fields.permissions = res.data.permissions.map(item => item.id)
            });   
    },
    methods: {
        updateRole: function() {

            this.loading = true;

            let data = {
                id: this.$route.params.id,
                fields: this.fields
            }

            this.$store.dispatch("updateRole", data)
                .then(res => {

                    this.success = true;

                    let self = this;

                    setTimeout(function() {
                        self.$router.push("/super/roles");
                    }, 2000)

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.status == 422){

                        this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                    }

                    this.error = true;

                    this.error_message = err.response.data.message;

                    this.loading = false;
                });
        },
    },
    computed: {
        crumbs: function() {
        return [
            {
                text: 'Listagem de Roles',
                disabled: false,
                to: '/super/roles',
                exact: true,
            },
            {
                text: 'Atualizar role',
                disabled: true,
                to: '#',
                exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>

</style>