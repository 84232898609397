<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Exportações</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
            </v-col>
            <v-col cols="12" offset="0" md="auto" offset-md="1">
                <v-select
                    v-model="fields.type"
                    :items="items"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined
                    label="Ficheiro *"
                ></v-select>
            </v-col>
            <v-col cols="12" offset="0" md="6" v-if="fields.type == 'diary'">
                <v-autocomplete
                    v-model="fields.work_id"
                    :items="works"
                    :item-text="item => item.Codigo +' - ' + item.Descricao"
                    dense
                    item-value="Id"
                    label="Obra *"
                    outlined
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="2" v-if="fields.type == 'diary' || fields.type =='manouver_diary'" offset-md="1">
                <DateInput 
                    label="Data Inicial *"
                    :outlined="true"
                    placeholder="Insira Data Inicial"
                    v-model="fields.init"
                    rules="required"
                />
            </v-col>
            <v-col cols="12" md="2" v-if="fields.type == 'diary' || fields.type =='manouver_diary'">
                <DateInput 
                    label="Data Final *"
                    placeholder="Insira Data Final"
                    :outlined="true"
                    v-model="fields.end"
                    rules="required"
                />
            </v-col>
            <v-col cols="12" offset="0" md="10" offset-md="1" align="center">
                <v-btn class="success" rounded @click="exportFile">Exportar</v-btn>
            </v-col>
        </v-row>
    <ErrorSnackbar v-model="error.show" :message="error.message"/>
    <Loader v-if="loader"/>
    </v-container>
</template>
<script>
import DateInput from "@/components/UI/Inputs/DateInput"
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import Loader from "@/components/UI/Loaders/Loader";

export default{
    name: "Exports",
    components:{
        DateInput,
        ErrorSnackbar,
        Loader
    },
    data(){
        return{
            loader: false,
            error:{
                show: false,
                message: ''
            },
            fields:{
                type: null,
                work_id: null,
                init: null,
                end: null,
                extension: 'Excel'
            },
            works: []
        }
    },
    mounted(){
        if(!this.$root.session.hasPermission(["super", "exports.create"])) {
            this.$router.push('/forbidden');
        }

        this.fillBaseData()

        if(this.items.length == 1)
            this.fields.type="diary"
    },
    methods:{
        fillBaseData(){
            this.$store.dispatch("listObras")
            .then(res => {
                let obra = res.data
                obra.forEach(element => {
                    if(element.Tipo == 'O')
                        this.works.push(element)
                });
            })   
        },
        exportFile(){
            this.loader = true
            let params = this.fields

            this.$store.dispatch('export_file', params)
                .then(res => {
                    this.loader = false
                    if(res.data.path == "0"){
                        this.error.message = "Não há diárias para mostrar com esses filtros"
                        this.error.show = true
                        return
                    }

                    window.open(res.data.url, '_blank')
                })
                .catch(err => {

                    console.log(err)
            });
        }
    },
    computed: {
        items(){
            let returner = []
            
            returner.push(
                {
                    id: 'diary',
                    name: "Partes Diárias",
                    show: this.$store.getters.hasPermission(['super', 'export.diaries'])
                },
                {
                    id: 'manouver_diary',
                    name: 'Diárias Manobrador',
                    show: this.$store.getters.hasPermission(['super', 'diaries.export_manouver_diary']),
                }
            )

            let final_ar = []
            returner.forEach(element => {
                if(element.show)
                    final_ar.push(element)
            });


            return final_ar
        },
        crumbs: function() {
        return [
            {
            text: 'Exportações',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>