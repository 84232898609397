<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Cartões de utilizador</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>


                <v-progress-linear
                    class="mb-8"
                    color="primary"
                    indeterminate
                    v-if="loading"
                ></v-progress-linear>
                <Form ref="pd" :loading="loading" @submited="createWorkerCard">
                <v-row>
                    <v-col cols="12" v-if="$route.params.id == undefined">                                                                 
                        <SearchUserById 
                            :funcionarios="funcionarios"
                            :userName="searchByIdName"     
                            :selectedFunc="fields.worker"                                         
                            v-model="fields.worker"
                            />
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            v-if="$route.params.id == undefined"
                            :items="funcionarios"
                            item-text="Nome"
                            label="Funcionário"
                            vid="funcionario"
                            class="basic"
                            v-model="fields.worker"
                            return-object
                            @change="render1++"
                        />
                        <div v-else>
                            <v-row class="mb-8">
                                <h2>Funcionário:</h2>
                                <label class="ml-6 mt-2">{{ workerName }}</label>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="6">                        
                        <Input 
                            label="Código do cartão"
                            placeholder="Insira o código do cartão"
                            v-model="fields.codigo_cartao"
                            rules="required"
                        />
                    </v-col>
                </v-row>
                </Form>
            </v-col>
        </v-row>
        <SuccessSnackbar message="Operação realizada com sucesso" v-model="success"/>
    </v-container>
</template>
<script>
import Select from "@/components/UI/Inputs/Select";
import Form from "@/components/UI/Forms/Form";
import Input from "@/components/UI/Inputs/Input";
import SearchUserById from "@/components/UI/Inputs/SearchUserById"
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

export default {
    components:{
        Input,
        Form,
        Select,
        SearchUserById,
        SuccessSnackbar
    },
    data(){
        return{
            render1: 0,
            loading: true,
            success: false,
            searchByIdName:'',
            funcionarios: [],
            fields:{
                worker: null,   
                codigo_cartao: null
            }
        }
    },
    mounted(){
        
        if(!this.$store.getters.hasPermission(["super", "worker_card.create"])) {
            this.$router.push('/forbidden');
        }
        
        if(this.$route.params.id != undefined){
            
            this.$store.dispatch("getWorkerCard", this.$route.params.id)
                .then((res) => {
                    this.fields = res.data
            }); 
            
        }else{
            this.fields.work = null
            this.fields.starting_hour = null
            this.fields.finishing_hour = null
        }
        this.fillData()
        
        this.loading = false
    },
    methods:{
        createWorkerCard(){
            if(this.$route.params.id){
                this.$store.dispatch("updateWorkerCard", this.fields)
                    .then(res => {

                        this.success = true;

                        let self = this;

                        setTimeout(function() {
                            self.$router.push("/frota/worker_card");
                        }, 2000)

                        this.loading = false;
                    })
                    .catch(err => {

                        if(err.response.status == 422){

                            this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                        }

                        this.error = true;

                        this.error_message = err.response.data.message;

                        this.loading = false;
                    });
            }else{
                this.$store.dispatch("createWorkerCard", this.fields)
                    .then(res => {

                        this.success = true;

                        let self = this;

                        setTimeout(function() {
                            self.$router.push("/frota/worker_card");
                        }, 2000)

                        this.loading = false;
                    })
                    .catch(err => {

                        if(err.response.status == 422){

                            this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                        }

                        this.error = true;

                        this.error_message = err.response.data.message;

                        this.loading = false;
                    });
            }
        },
        fillData(){
            if(this.$route.params.id == undefined){
            this.$store.dispatch("listFuncionarios")
                .then(res => {
                    this.funcionarios = res.data
                })  
            }
        }
    },
    computed: {
        workerName(){
            if(this.fields == null)
                return ''
            if(this.fields.worker == null)
                return ''
            if(this.fields.worker.Nome == null)
                return ''

            return this.fields.worker.Nome
        },
        crumbs: function() {
            return [
                {
                text: 'Horário de obras',
                disabled: true,
                to: '#',
                exact: true,
                },
            ];
        }
    }
}
</script>