<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Listagem de Roles</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>

                <PaginatedTable
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :server-items-length="total"
                    @optionsChanged="searchRoles"
                    class="mt-4"
                    locale="pt-pt"
                    :dataSearch="false"
                    :canView="true"
                    :canDelete="$store.getters.hasPermission(['super', 'roles.delete'])"
                    @viewed="viewRole"
                    @deleted="deleteRole"
                >
                </PaginatedTable>        
            </v-col>
        </v-row>

        <SuccessSnackbar message="Role Removido!" v-model="success"/>
    </v-container>
</template>

<script>
import Tooltip from "@/components/UI/Tooltips/Tooltip"

import PaginatedTable from "@/components/UI/Tables/PaginatedTable"

import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

export default {
    components: {
        Tooltip,
        PaginatedTable,
        SuccessSnackbar
    },
    data: () => ({
        headers: [
            {
                text: 'Nome',
                sortable: false,
                value: 'display_name',
            },
            { 
                text: 'Opções', 
                value: 'actions', 
                sortable: false 
            },
        ],
        loading: false,
        success: false,
        options: {},
        items: [],
        total: 0,
        filter: {
            name: null,
            email:null
        },
    }),
    mounted(){
        if(!this.$store.getters.hasPermission(["super", "roles.read"])) {
            this.$router.push('/forbidden');
        }
    },
    methods: {
        searchRoles(options){
            this.options = options;

            this.loading = true;

            let filter = {...this.filter};

            filter.page = options.page;

            filter.limit = options.itemsPerPage;

            this.$store.dispatch("searchRoles", filter)
                .then(response => {

                    let items = response.data;

                    this.total = items.total;

                    this.items = items.data;

                    this.loading = false;

            });
        },
        viewRole(role){
            this.$router.push("/super/roles/" + role.id);
        },
        deleteRole(role){
            this.$store.dispatch("deleteRole", role.id)
                .then(response => {

                    this.success = true;

                    this.searchRoles(this.options);

            });
        }
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Listagem de Roles',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>

</style>