<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-briefcase</v-icon> <span>Registo de Unidade e Obra</span>
        </h1>
  
        <div class="mt-16">
          <Articulado_UnidadeObra method="create"></Articulado_UnidadeObra>
        </div>
    </section>
  </template>
  <script>
  import Articulado_UnidadeObra from '@/components/Articulados/Articulado_UnidadeObra.vue'
  
  export default {
    components: {
      Articulado_UnidadeObra
    },
    beforeCreate(){
      document.title = "ODC - Criar Obra vs Unidade Obra";
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super"])) {
        this.$router.push('/forbidden');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão Unidades de Obra',
            disabled: false,
            to: '/admin/works-settings/units',
            exact: true,
          },
          {
            text: 'Registo de Unidade de Obra',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  