<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="12" offset-md="0">
                <MenuTabs :headers="headers">
                    <template v-slot:eq>
                        <v-card
                            tile
                        >
                            <div class="pa-10">
                                <EqDiaries/>
                            </div>
                        </v-card>
                    </template>

                    <template v-slot:mo>
                        <v-card
                            tile
                        >
                            <div class="pa-10">
                                <MODiaries/>
                            </div>
                        </v-card>
                    </template>
                </MenuTabs>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MenuTabs from "@/components/UI/Tabs/MenuTabs";

import EqDiaries from "@/views/Diaries/EqDiaries";

import MODiaries from "@/views/Diaries/MODiaries";

export default {
    components: {
        MenuTabs,
        EqDiaries,
        MODiaries
    },
    data: () => ({
        headers: [
            {
                id: "eq",
                name: "Equipamento",
                icon: "mdi-bulldozer"
            },
            {
                id: "mo",
                name: "Mão de Obra",
                icon: "mdi-hand-back-right"
            },
        ],
    }),
    mounted(){
        if(!this.$store.getters.hasPermission(["super", "diaries.read"])) {
            this.$router.push('/forbidden');
        }
    }
}
</script>

<style scoped>

</style>