<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <h2 class="primary--text">Consulta de diárias</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        
        <div style="position: relative;">
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-row class="caption mx-4 my-4">
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                :items="obras"
                                v-model="filter.work_id"
                                outlined dense
                                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                item-value="Id"
                                label="Obra a procurar"
                            />
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-btn :disabled="filter.work_id == null" @click="searchButton" small fab class="success">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="showContent">            
            <v-col cols="12">
                <v-speed-dial
                    absolute
                    v-model="speedDial"
                    top
                    right
                    direction="bottom"
                    :open-on-hover="true"
                >
                    <template v-slot:activator>
                        <v-tooltip right color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                v-bind="attrs" v-on="on"
                                v-model="speedDial"
                                color="primary"
                                dark
                                x-large
                                fab
                                >
                                <v-icon v-if="speedDial">
                                    mdi-close
                                </v-icon>
                                <v-icon v-else>
                                    mdi-dots-vertical
                                </v-icon>
                                </v-btn>
                            </template>
                            <span>Opções</span>
                        </v-tooltip>
                    </template>
                    <v-tooltip right color="secondary">
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            fab
                            dark
                            small
                            color="secondary"
                            large
                            @click="searching = true"
                        >
                            <v-icon>mdi-filter</v-icon>
                        </v-btn>
                        </template>
                        <span>Pesquisar</span>
                    </v-tooltip>
                </v-speed-dial>
                <v-data-table
                    :expanded.sync="expanded"
                    show-expand
                    single-expand
                    :headers="headers"
                    :loading="loading"
                    :server-items-length="total"
                    :items="items"
                    :footer-props="tableFooter"
                    :options.sync="options"
                    group-by="data"
                    class="elevation-0"
                >
                <template v-slot:item.creator="{item}">
                    <div class="d-none d-lg-block">
                        <Tooltip
                            class="my-4 mr-4"
                            color="primary"
                            :text="item.creator_name"
                            top
                        >
                            {{item.creator}}
                        </Tooltip>
                    </div>
                    <div class="d-md-none">{{item.creator}} - {{item.creator_name}}</div>
                </template>

                <template v-slot:group.header="{ group, items, headers, toggle, isOpen }">
                    <td :colspan="headers.length">
                        <v-row>
                            <v-col cols="12" md="2" class="font-weight-bold">
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                                            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                                            <v-icon v-else>mdi-chevron-down</v-icon>
                                        </v-btn>
                                        {{group}} 
                                        <v-btn class="success" fab x-small @click=editDay(group)>
                                            <v-icon small>mdi-eye</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="10" align="end" class="font-weight-bold">
                                ({{items.length}})
                            </v-col>
                        </v-row>
                    </td>
                </template>

                <template v-slot:item.approval_status="{item}">
                    <v-row>
                        <v-col cols="12" md="auto">
                            <v-icon small color="warning" v-if="item.creation_role == 'manobrador'">mdi-steering</v-icon>
                            <v-icon small color="black" v-if="item.creation_role == 'encarregado'">mdi-account-hard-hat</v-icon>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-icon small color="success" v-if="checkApprovalStatus(item) == 'total'">mdi-check-decagram</v-icon>
                            <span v-else>{{checkApprovalStatus(item)}}</span>
                        </v-col>
                        <v-col cols="12" v-if="item.parque_approved" md="auto">
                            <v-icon small color="error">mdi-parking</v-icon>
                        </v-col>
                        <v-col cols="12" md="2" v-if="false">
                            <v-icon v-if="item.sincronizada" color="success">mdi-sync</v-icon>
                            <v-icon v-else>mdi-sync-off</v-icon>
                        </v-col>
                    </v-row>
                </template>

                <template v-slot:item.actions="{item}">
                    <v-row>
                        <v-col cols="12" md="auto">
                            <v-btn
                                fab
                                dark
                                x-small
                                outlined
                                color="warning"
                                @click="edit(item)"
                            >
                                <v-icon dark>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="auto" v-if="checkIfCanApprove(item)">
                            <v-btn
                                fab
                                dark
                                x-small
                                outlined
                                color="success"                            
                                @click="confirmApprove(item)"
                            >
                                <v-icon dark>
                                    mdi-check-decagram
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-btn fab x-small outlined color="blue" @click="openPreviewDiary(item.id)">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="auto" v-if="canDelete(item)">
                            <v-btn
                                fab
                                dark
                                x-small
                                outlined
                                color="error"                                    
                                @click="confirmDeleteDiary(item)"
                                >
                                    <v-icon dark>
                                        mdi-delete
                                    </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">                        
                        <PreviewDiary :diary_id="item.id" />
                    </td>
                </template>
                
                </v-data-table>
            </v-col>
        </v-row>
        </div>

        <v-navigation-drawer
          fixed
          left
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
            <v-list>
                <v-list-item>
                    <v-btn fab small color="secondary" @click="searching = false">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-btn absolute right small color="error" @click="resetFields">
                        Limpar campos
                    </v-btn>
                </v-list-item>
            </v-list>
            <v-list class="pt-10">
                <v-form @submit.prevent="searchDiaries">
                    
                    <v-list-item>
                        <v-autocomplete
                            :items="[
                                {text: 'Todas', value: 0},
                                {text: 'Por aprovar', value: 1},
                                {text: 'Aprovadas administrativo', value: 2},
                                {text: 'Totalmente aprovadas', value: 3}, 
                            ]"
                            item-text="text"
                            item-value="value"
                            label="Estado diárias"
                            dense outlined
                            v-model="filter.status"
                        />
                    </v-list-item>

                    <v-list-item>
                        <v-autocomplete
                            :items="['manobrador', 'encarregado']"
                            label="Role"
                            dense outlined
                            v-model="filter.role"
                        />
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-menu
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="filter.start_date"
                                        label="Data inicial"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="filter.start_date"
                                        :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                        @input="menu1 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="filter.end_date"
                                        label="Data final"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="filter.end_date"
                                        :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                        @input="menu2 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-row
                    class="mt-10"
                    align="center"
                    justify="space-around"
                    >
                    <v-btn
                    color="primary"
                    type="submit"
                    >
                    Procurar
                    </v-btn>
                    </v-row>
                </v-form>
            </v-list>
        </v-navigation-drawer>

        <DialogConfirmation 
            v-if="confirmationModal.show"
            :title="confirmationModal.title" 
            type="warning" 
            :opened="confirmationModal.show" 
            :elevation="0"
            @on-submit="approve" 
            @on-cancel="confirmationModal.item = null; confirmationModal.show = false;">
                {{ confirmationModal.message }}
        </DialogConfirmation>

        <DialogConfirmation 
            v-if="confirmationModalDelete.show"
            :title="confirmationModalDelete.title" 
            type="warning" 
            :opened="confirmationModalDelete.show" 
            :elevation="0"
            @on-submit="deleteDiary" 
            @on-cancel="confirmationModalDelete.item = null; confirmationModalDelete.show = false;">
                {{ confirmationModalDelete.message }}
        </DialogConfirmation> 

        <v-dialog width="80%" height="80%" v-model="previewDiary.show">
            <v-card>
                <PreviewDiary v-if="previewDiary.show" :diary_id="previewDiary.id" />
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
import Obra from '@/api/Obra.js'
import Diaries from "@/api/Diaries.js"
import Approval from "@/api/Approval.js"
import User from "@/api/User.js"
import DialogConfirmation from '@/components/UI/Modals/DialogConfirmation.vue';
import DateBetweenPicker from "@/components/UI/Inputs/DateBetweenPicker"
import PreviewDiary from "@/views/Diaries/Preview/PreviewDiary.vue"
import Tooltip from "@/components/UI/Tooltips/Tooltip"

export default{
    name: "DiariesV2",
    components:{
        DialogConfirmation,
        DateBetweenPicker,
        PreviewDiary,
        Tooltip
    },
    watch:{
        'options': {
            immediate: false,
            handler () {
            setTimeout(() => {this.searchDiaries()}, 10);
            },
            deep: true,
        },
    },
    data(){
        return{            
            obras: [],
            expanded: [],
            speedDial: false,
            showContent: false,
            menu1: false,
            menu2: false,
            loading: true,
            total: 0,
            searching: false,
            choices: [
                {
                    id: "eq",
                    name: "Equipamento",
                    icon: "mdi-bulldozer"
                },
                {
                    id: "mo",
                    name: "Mão de Obra",
                    icon: "mdi-hand-back-right"
                },
            ],
            headers:[
                {
                    text: 'Status',
                    sortable: false,
                    value: 'approval_status',
                    align: "center",
                },
                {
                    text: 'Obra',
                    sortable: false,
                    value: 'chave',
                    align: "center",
                },
                {
                    text: 'Data',
                    sortable: false,
                    value: 'data',
                    align: "center",
                },
                {
                    text: 'Resp. Aprovação',
                    sortable: false,
                    value: 'responsavel',
                    align: "center",
                },
                {
                    text: 'Criada Em',
                    value: 'created_at',
                    sortable: false,
                    align: "center",
                },
                {
                    text: 'Criada Por',
                    sortable: false,
                    value: 'creator',
                    align: "center",
                },
                { 
                    text: 'Opções', 
                    value: 'actions',
                    align: 'center',
                    sortable: false,    
                },
            ],
            items: [],
            tableFooter: {
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': "Linhas por página"
            },
            options: {},
            filter: {
                type: 'daily',
                work_id: null
            },
            tota: 0,
            confirmationModal:{
                show: false,
                title: '',
                message: '',
                approvalItem: null
            },            
            confirmationModalDelete:{
                show: false,
                title: '',
                message: '',
                approvalItem: null
            },
            previewDiary:{
                id: null,
                show: false
            },
            obras_user_tem_acesso: []
        }
    },
    mounted(){
        if(!this.$store.getters.hasPermission(["super", "diaries.read"])) {
            this.$router.push('/forbidden');
        }
        this.fillBaseData();
    },
    methods:{       
        searchButton(){
            this.showContent = false
            this.showContent = true
            this.searchDiaries()

            
        }, 
        async fillBaseData(){
            this.loading = true
            let data = {
                tipo: 'O'
            }
            
            await Obra.all(data).then((resp)   =>  {
                this.obras = resp.data;
            
            });
            
            User.getUserObras(this.$store.state.user.data.id).then((resp)    =>  {
                this.obras_user_tem_acesso = resp.data
            })

            this.loading = false

        },
        openPreviewDiary(id){
            this.previewDiary.id = id
            this.previewDiary.show = false
            this.previewDiary.show = true
        },
        resetFields(){
            this.filter = {
                type: 'eq',
                start_date: null,
                end_date: null,
                role: null,
                work_id: null
            }
            this.searchDiaries()
        },
        async approve(){
            let approval = this.confirmationModal.item
            if(approval != null){
                // Send hash from Header
                await Approval.approve(approval)
                    .then(response => {
                        this.confirmationModal.item = null
                        this.confirmationModal.show = false

                        this.searchDiaries()

                    }).catch(err => {
                        reject(err);
                    }); 
                
            }
        },    
        confirmApprove(approval){
            this.confirmationModal.item = approval
            this.confirmationModal.title = 'Tem a certeza?'
            this.confirmationModal.message = 'Tem a certeza que pretende aprovar a diária?'
            this.confirmationModal.show = true
        },
        editDay(day){
            //this.$router.push('/diarios/dia/' + day + '/' + this.filter.work_id)
            window.open('/diarios/dia/' + day + '/' + this.filter.work_id, '_blank')
        },
        edit(item){
            if(item.creation_role == "encarregado"){
                //this.$router.push("/diarios/encarregado/editar/" + item.webAppLinkHash + "/" + item.id_user_creator);
                window.open("/diarios/encarregado/editar/" + item.webAppLinkHash + "/" + item.id_user_creator, '_blank')
                return
            }else{                
                //this.$router.push("/diarios/encarregado/editar/" + item.webAppLinkHash + "/" + item.id_user_creator);
                window.open("/diarios/encarregado/editar/" + item.webAppLinkHash + "/" + item.id_user_creator, '_blank')
                return
            }
        },
        checkApprovalStatus(item){
            if(item.creation_role == 'encarregado' && item.level_needed_to_approve == '3'){
                item.level_needed_to_approve = 2
                if(item.current_approval != null){
                    if(item.current_approval.level == "1")
                        item.current_approval.level = 0         
                    if(item.current_approval.level == "2")
                        item.current_approval.level = 1   
                }      
            }


            if(item.current_approval == null)
                return '0 / ' + item.level_needed_to_approve

            if(item.current_approval.level >= item.level_needed_to_approve)
                return 'total'
            
            return item.current_approval.level + ' / ' + item.level_needed_to_approve
        },
        async searchDiaries(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }

            this.loading = true;

            let filter = {...this.filter};
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage
            };
            
            
            Object.assign(request, filter);

            localStorage["search-diaries-v2-" + this.$store.state.user.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });

            await Diaries.search(request).then(response => {

                let data = response.data;

                this.total = data.total;

                this.items = data.data.map((item) => {

                    this.searching = false;

                    item.closed = true

                    return item;
                });


                this.loading = false;

                

            });

            // Fechar automaticamente todas as linhas da datatable group
            Object.keys(this.$refs).forEach(k => {
                if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
                    this.$refs[k].$el.click()
                }
            })
            
        },   
        canDelete(item){
            if(!this.$store.getters.hasPermission('super', 'diaries.delete'))
                return false;

            if(item.webAppLinkHash == null)
                return false

            if(item.sync == true)
                return false;

            return true;
        },            
        confirmDeleteDiary(diary){
            this.confirmationModalDelete.item = diary
            this.confirmationModalDelete.title = 'Tem a certeza?'
            this.confirmationModalDelete.message = 'Tem a certeza que pretende eliminar a diária?'
            this.confirmationModalDelete.show = true
        },            
        deleteDiary(){
            let diary = this.confirmationModalDelete.item
            let params = {
                id_user_creator: diary.id_user_creator,
                id: diary.webAppLinkHash
            }


            Diaries.deleteDiary(params, diary.webAppLinkHash).then((resp)  =>  {
                this.success = true;
                this.confirmationModalDelete.show = false
                this.searchDiaries(this.options);
            })
            
        },             
        checkIfCanApprove(item){
            let approval = item.current_approval


            // Verficar primeiro o role... Se não tiver role de encarregado, administrativo ou engenheiro, não pode aprovar
            if(!this.$store.getters.hasRole(['encarregado', 'administrativo', 'engenheiro', 'parque']))
                return false

                    

            // Verificar se tem acesso a estas obras. Se não tiver, não pode aprovar
            let acesso_user = []
            //this.$store.state.user.data.obras.forEach(element => {
            this.obras_user_tem_acesso.forEach(element => {
                acesso_user.push(element.Codigo)
            });

            
            if(!acesso_user.includes(item.chave))
                if(!this.$store.getters.hasRole(['parque']))
                    return false;

            // verificar o nível que é necessário
            // se for de 3 camadas, é um encarregado
            if(parseInt(item.level_needed_to_approve) == 3){
                // Se está a null, significa que é preciso ser aprovado pelo ENCARREGADO
               
                if(approval == null){
                    if(this.$store.getters.hasRole(['administrativo', 'engenheiro', 'parque']))
                        return false

                    return true
                }

                if(parseInt(approval.level) == 0){
                    return this.$store.getters.hasRole(['encarregado'])
                }

                
                // Se tem approval, verificar qual o role de aprovação. 
                // Se estiver a 1, significa que o ADMINISTRATIVO já aprovou
                if(approval.level == 1){
                    return this.$store.getters.hasRole(['administrativo'])
                }

                if(parseInt(approval.level) == 2){
                    if(this.$store.getters.hasRole(['parque']))
                        if(item.parque_approved)
                            return true
                        else
                            return false
                }

                // Se tem approval, verificar qual o role de aprovação. 
                // Se estiver a 1, significa que o ADMINISTRATIVO já aprovou
                if(approval.level == 2){
                    return this.$store.getters.hasRole(['engenheiro'])
                }
                
                // Se estiver a 2, significa que já foi totalmente aprovado e não precisa de mais aprovações
                return false
            }else{

                // Verficar primeiro o role... Se não tiver role de encarregado, administrativo ou engenheiro, não pode aprovar
                if(!this.$store.getters.hasRole(['administrativo', 'engenheiro', 'parque']))
                    return false
                
                
                // Se está a null, significa que é preciso ser aprovado pelo ADMINISTRATIVO
                if(approval == null){
                    if(this.$store.getters.hasRole(['engenheiro']))
                        return false

                    if(this.$store.getters.hasRole(['parque']))
                        return false
                        
                    return true
                }


                if(parseInt(approval.level) == 0){
                    return this.$store.getters.hasRole(['administrativo'])
                }

                if(parseInt(approval.level) == 1){
                    if(this.$store.getters.hasRole(['parque'])){
                        if(item.parque_approved)
                            return false
                        else
                            return true
                    }
                }
                
                
                
                // Se tem approval, verificar qual o role de aprovação. 
                // Se estiver a 1, significa que o ADMINISTRATIVO já aprovou
                if(approval.level == 1){
                    return this.$store.getters.hasRole(['engenheiro'])
                }
                
                // Se estiver a 2, significa que já foi totalmente aprovado e não precisa de mais aprovações
                return false
            }
        },
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Consulta de diárias',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>