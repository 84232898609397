<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Registar Role</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>
                
                <Form
                    ref="form"
                    submitText="Gravar"
                    @submited="createRole"
                    :loading="loading"                    
                >
                    <v-row>
                        <v-col cols="12" md="6">
                            <Input
                                v-model="fields.name"
                                label="ID"
                                rules="required"
                                dense outlined
                                vid="name"
                            />
                        </v-col>

                        <v-col cols="12" md="6">
                            <Input
                                v-model="fields.display_name"
                                label="Nome"
                                rules="required"
                                dense outlined
                                vid="display_name"
                            />
                        </v-col>

                        <v-col cols="12">
                            <MultipeSelect
                                v-model="fields.permissions"
                                :items="permissions"
                                item-text="display_name"
                                item-value="id"
                                dense outlined
                                label="Permissões"
                                rules="required"
                                vid="permissions"
                            />
                        </v-col>
                    </v-row>
                </Form>

                <SuccessSnackbar message="Dados gravados!" v-model="success"/>

                <ErrorSnackbar v-model="error" :message="error_message"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import MenuTabs from "@/components/UI/Tabs/MenuTabs";

    import Form from "@/components/UI/Forms/Form";

    import Input from "@/components/UI/Inputs/Input";

    import MultipeSelect from "@/components/UI/Inputs/MultipeSelect";

    import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

    import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";

export default {
    components: {
      MenuTabs,
      Form,
      Input,
      SuccessSnackbar,
      ErrorSnackbar,
      MultipeSelect,
    },
    data: () => ({
      fields: {
          name: null,
          display_name: null,
          permissions: []
      },
      loading: false,
      success: false,
      error: null,
      permissions: [],
      error_message: "",
    }),
    mounted: function() {
        
        if(!this.$store.getters.hasPermission(["super", "roles.update", "roles.create"])) {
            this.$router.push('/forbidden');
        }

        this.$store.dispatch("listPermissions")
            .then(res => {
                this.permissions = res.data;
            })
    },
    methods: {
        createRole: function() {

            this.loading = true;

            this.$store.dispatch("createRole", this.fields)
                .then(res => {

                    this.success = true;

                    let self = this;

                    setTimeout(function() {
                        self.$router.push("/super/roles");
                    }, 2000)

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.status == 422){

                        this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                    }

                    this.error = true;

                    this.error_message = err.response.data.message;

                    this.loading = false;
                });
        },
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Registar role',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>

</style>