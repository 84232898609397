<template>
    <section class="mt-16">
      <v-row>
        <v-col cols="12" offset="0" md="10" offset-md="1">    
          <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
          ></v-breadcrumbs>
    
          <h1 class="primary--text">
            <v-icon color="primary">mdi-apps-box</v-icon> <span>Listagem de Obras vs UO</span>
          </h1>
          
          <div style="position:relative">
            <v-container>
                <v-speed-dial
                    absolute
                    v-model="speedDial"
                    top
                    right
                    direction="bottom"
                    :open-on-hover="true"
                >
                    <template v-slot:activator>
                    <v-tooltip right color="primary">
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            v-model="speedDial"
                            color="primary"
                            dark
                            x-large
                            fab
                        >
                            <v-icon v-if="speedDial">
                            mdi-close
                            </v-icon>
                            <v-icon v-else>
                            mdi-dots-vertical
                            </v-icon>
                        </v-btn>
                        </template>
                        <span>Opções</span>
                    </v-tooltip>
                    </template>
                    <v-tooltip right color="secondary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        v-bind="attrs" v-on="on"
                        fab
                        dark
                        small
                        color="secondary"
                        large
                        to="/kpi/work_work_units/create"                        
                        >
                        <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Registar</span>
                    </v-tooltip>
                </v-speed-dial>
            </v-container>
    
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="total"
              :loading="loading"
              :footer-props="tableFooter"
              :options.sync="options"
              class="mt-16"
              locale="pt-pt"
            >
            <template v-slot:item.actions="{ item }">

              <IconView
                  class="mr-2"
                  @click="viewData(item)"
              />

              </template>
              
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </section>
  </template>
  <script>
  import dayjs from 'dayjs';
  import UnidadesObra from "@/api/UnidadesObra.js";
  import IconView from '@/components/UI/IconView.vue';
  
  export default {
    components: {
      IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchWorkWorkUnits()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "ODC - Listagem de Obras vs UO";
    },
    data: () => ({      
      headers: [
        {
          text: 'Cód. Obra',
          sortable: true,
          value: 'work_code',
        },
        {
          text: 'Obra',
          sortable: true,
          value: 'work_name',
        },
        {
            text: "Opções",
            align: "center",
            value: "actions"
        }
      ],
      items: [],
      total: 0,
      loading: true,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
      },
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "categories.read"])) {
        this.$router.push('/forbidden');
      }
  
      this.fillBaseData();
    },
    methods:{      
      fillBaseData() {
  
        if(localStorage["work_work_unit-list-" + this.$store.state.user.data.id] != undefined){
  
          let data = JSON.parse(localStorage["work_work_unit-list-" + this.$store.state.user.data.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchWorkWorkUnits(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["work_work_unit-list-" + this.$store.state.user.data.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        UnidadesObra.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      viewData(item){
        this.$router.push('/kpi/work_work_units/' + item.id);
      },
      resetWorkWorkUnits()
      {
        this.filter = {
        };
  
        this.searchWorkWorkUnits();
      },
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Listagem de Obras vs UO',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  