<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Gestão Subempreitadas</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>

                <v-row>
                    <v-col cols="12" md="2">
                        <v-btn
                            v-if="this.$store.getters.hasPermission(['super', 'subcontract.create'])"
                            tile
                            color="primary"
                            small
                            @click="newDialog = true"
                        >
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                            Adicionar novo
                        </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="2">
                        <div class="d-none d-lg-block text-right">
                            <v-btn
                                color="primary"
                                dark
                                small
                                tile
                                @click="exportSubEmpreitada()"
                                >
                                <v-icon left>
                                    mdi-export
                                </v-icon>
                                Exportar
                                </v-btn>
                        </div>
                    </v-col>
                </v-row>
                
            </v-col>     
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <v-data-table      
                    :headers="headers"
                    :items="sub_empreitadas"
                    :server-items-length="total"
                    :options.sync="options"
                    :footer-props="tableFooter"
                    locale="pt-pt"        
                    class="elevation-4"
                >
                    <template v-slot:item.obra="{ item }">
                        {{item.obra == null ? '-' : item.obra.Descricao}}
                    </template>
                    <template v-slot:item.frente="{ item }">
                        {{item.frente == null ? '-' : item.frente.Descricao}}
                    </template>
                    <template v-slot:item.sub="{ item }">
                        {{item.sub == null ? '-' : item.sub.Descricao}}
                    </template>
                    <template v-slot:item.actions="{ item }">

                        <IconRemove
                            class="mr-2"
                            @on-submit="deleteSub(item)"
                        />

                    </template>
                </v-data-table>
            </v-col>
            <v-dialog
                v-model="newDialog"
                persistent
                max-width="600px"
            >
            
            <AddSubempreitada
                @success="reloadWindow"
                @close="newDialog = false"
            />
            </v-dialog>
        </v-row>

        <SuccessSnackbar message="Dados gravados!" v-model="successSubempreitada"/>

        
        <v-dialog
            v-model="chooseDate"
            width="500"
        >
        <DatesPicker @confirm="startExport" @close="chooseDate = false"/>
        </v-dialog>
    </v-container>
</template>
<script>
import PaginatedTable from "@/components/UI/Tables/PaginatedTable";
import AddSubempreitada from "@/components/UI/Modals/AddSubempreitada";
import DatesPicker from "@/components/UI/Modals/DatesPicker.vue";
import FrenteObra from '@/api/FrenteObra.js'
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import IconRemove from '@/components/UI/IconRemove.vue';

export default {
    components:{
        PaginatedTable,
        AddSubempreitada,
        DatesPicker,
        SuccessSnackbar,
        IconRemove
    },
    watch: {
        'options': {
        immediate: false,
        handler () {
            setTimeout(() => {this.loadData()}, 10);
        },
        deep: true,
        },
    },
    data(){
        return{
            chooseDate: false,
            success: false,
            successSubempreitada: false,
            newDialog: false,
            error: false,
            total: 0,
            loading: false,
            sub_empreitadas: [],
            headers:[
                {
                    text: 'Obra',
                    sortable: false,
                    value: 'obra'
                },
                {
                    text: 'Frente Obra',
                    sortable: false,
                    value: 'frente'
                },
                {
                    text: 'Sub-Empreitada',
                    sortable: false,
                    value: 'sub',
                },
                {
                    text: 'Unidade',
                    sortable: false,
                    value: 'unidade',
                },
                {
                    text: 'Opções',
                    sortable: false,
                    value: 'actions',
                },
            ],
            filter: {
                
            },
            options: {},
            tableFooter: {
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': "Linhas por página"
            },
        }
    },
    methods:{        
        reloadWindow(){
            this.successSubempreitada = true
            this.newDialog = false
            this.loadData()
        },
        async startExport(dt1, dt2, extension, secondOption){
            let params = {
                init: dt1,
                end: dt2,
                extension: extension,
                secondOption: secondOption,
                type: 'subempreitadas'
            }
            await this.$store.dispatch("export_file", params)
            .then((res) => {
                
                this.success = true;
                
                
                
                window.open(res.data.url, '_blank')
                
                result = res.data.path
                
                this.error = false;

                this.loading = false;

            }).catch(err => {

                console.log(err)
            });
        },
        async exportSubEmpreitada(){
            this.chooseDate = true
        },
        deleteSub(i){
            this.$store.dispatch("deleteSubEmpreitadaUnidades", i.id)
            .then((res) => {
                
                this.success = true;
                
                this.loadData()

                this.error = false;

                this.loading = false;

            }).catch(err => {

                console.log(err)
            });
        },
      loadData(){
            this.loading = true;

            let filter = {...this.filter};

            let request = {
                sortBy:     this.options.sortBy,
                sortDesc:   this.options.sortDesc,
                page:       this.options.page,
                limit:      this.options.itemsPerPage,
            };

            Object.assign(request, filter);

            localStorage["odc-subempreitadas-listings-" + this.$store.state.user.data.id] = JSON.stringify({
                filter: filter,
                options: this.options,
            });

            this.$store.dispatch("getListSubEmpreitadaUnidades", request)
            .then((res) => {
                
                this.success = true;
                this.sub_empreitadas = []
                this.sub_empreitadas = res.data
                this.total = res.total

                this.error = false;

                this.loading = false;

            }).catch(err => {

                console.log(err)
            });
            
      },
      fillBaseData(){
        /*FrenteObra.all().then((resp)  =>  {
            this.frentes = resp.data
        })*/
      }
    },
    mounted(){
        if(!this.$store.getters.hasPermission(["super", "subempreitadas.manage"])) {
            this.$router.push('/forbidden');
        }

        
        if(localStorage["odc-subempreitadas-listings-" + this.$store.state.user.data.id] != undefined){

            let data = JSON.parse(localStorage["odc-subempreitadas-listings-" + this.$store.state.user.data.id]);

            this.filter = data.filter;

            this.options = data.options;
        }
        this.fillBaseData()
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Gestão de Subempreitadas',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>
<!-- 'id_sub_empreitada' => $this->id_sub_empreitada,
            'unidade' => $this->unidade,
            "createdBy"  => config("app.ccop.user"),
            "createdIn"  => now()->toDateTimeString(),
            "modifiedBy" => config("app.ccop.user"),
            "modifiedIn" => now()->toDateTimeString(),
            'obra_id' => $this->obra_id,
            'frente_obra_id' => $this->frente_obra_id
        -->