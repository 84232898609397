<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Lista de Obras</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>


                <v-progress-linear
                    class="mb-8"
                    color="primary"
                    indeterminate
                    v-if="loading"
                ></v-progress-linear>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="works"
                            category="Work_Schedule"
                            @update="openDialog"
                            @delete-row="deleteRow"
                            :showBTN="this.$store.getters.hasPermission(['super', 'work_schedules.create'])"
                        >
                            <template v-slot:item.actions="{item}">
                                <Tooltip
                                    class="my-4 mr-4"
                                    color="primary"
                                    text="Visualizar"
                                    top
                                >
                                    <v-icon
                                        small
                                        color="primary"
                                        class="mr-2"
                                        @click="view(item)"
                                    >
                                        mdi-eye
                                    </v-icon>
                                </Tooltip>
                                <Tooltip
                                    class="my-4 mr-4"
                                    color="red"
                                    text="Eliminar"
                                    top
                                >
                                    <v-icon
                                        small
                                        color="red"
                                        class="mr-2"
                                        @click="confirmDelete(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </Tooltip>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                
            </v-col>
            <ConfirmModal @yes="deleteRow" @close-modal="confirmationModal.show = false" v-show="confirmationModal.show" :title="confirmationModal.title" :message="confirmationModal.message"/>

            <SuccessSnackbar message="Operação realizada com sucesso" v-model="success"/>
        </v-row>
    </v-container>
</template>
<script>
import Tooltip from "@/components/UI/Tooltips/Tooltip"
import PaginatedTable from "@/components/UI/Tables/PaginatedTable";
import ConfirmModal from '@/components/Modals/ConfirmModal.vue';

import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

export default {
    components:{
        PaginatedTable,
        Tooltip,
        ConfirmModal,
        SuccessSnackbar
    },
    data: () => ({
        loading: false,
        success: false,
        updateDialog: {
            show: false,
            linha: null
        },
        works: [],
        confirmationModal: {
            show: false,
            title: '',
            message: ''
        },
        itemToDelete: null,
        headers:[
            {
                text: "Obra",
                align: "center",
                value: "work.Descricao",
            },
            {
                text: "Hora Inicio",
                align: "center",
                value: "starting_hour"
            },
            {
                text: "Hora Fim",
                align: "center",
                value: "finishing_hour"
            },
            {
                text: "Opções",
                align: "center",
                value: "actions"
            }
        ]
    }),
    methods:{
        confirmDelete(line){
            this.itemToDelete = line
            this.confirmationModal.show = true
            this.confirmationModal.title = 'Tem a certeza?'
            this.confirmationModal.message = 'Verifique se tem a certeza que deseja prosseguir. Quer mesmo eliminar?'
        },
        deleteRow(){
            let line = this.itemToDelete
            this.$store.dispatch("deleteWorkSchedule", line.id)
            .then(() => {
                this.success = true;
                this.confirmationModal.show = false
                this.success = true

                this.error = false;

                this.updateDialog.show = false
                this.loadData()

                this.loading = false;

            }).catch(err => {

                console.log(err)
            });
            this.itemToDelete = null
        },
        view(line){
            this.$router.push('/frota/works_schedule/editar/' + line.id)
        },
        openDialog(ob){
            this.updateDialog.show = true
            this.updateDialog.linha = ob
        },
        loadData(){
            this.$store.dispatch("getWorksScheduled")
            .then((res) => {
                
                this.works = []
                this.works = res.data.data



                this.error = false;

                this.loading = false;

            }).catch(err => {

                console.log(err)
            });
        }
    },
    mounted(){
        if(!this.$store.getters.hasPermission(["super", "frotas.read"])) {
            this.$router.push('/forbidden');
        }
        this.loading = true
        this.loadData()

    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Lista de obras',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>